<template>
    <contenWrap>
        <div class="storeAccountManager">
            <div class="tool-card">
                <div class="add-btn-wrap">
                    <CreateBtn  name='发布商品' @cbCreateBtnClick="handleCreateStore"></CreateBtn>
                    <div class="filtrate-wrap">
                      <screenFromCard @screenData = "screenData" ref="screenFromCard"></screenFromCard>
                    </div>
                </div>
            </div>
            <div class="table-card">
                <goodsListCard ref="goodsListCard"></goodsListCard>
            </div>
        </div>

        <!-- 新建门店dialog -->
    </contenWrap>
</template>

<script>
import contenWrap from '@/components/contenWrap'
import CreateBtn from '@/components/CreateBtn'
import screenFromCard from "./components/screenFromCard";
import goodsListCard from "./components/goodsListCard";
import pageMixin from '@/internal/pageMixin'
export default {
    mixins:[pageMixin],
    components: {
        contenWrap,
        CreateBtn,
        screenFromCard,
        goodsListCard
    },
    data() {
        return {
            pageMixin_index:'sProductList',
            tableData:[],
            isShowAddStoreDialog:false,
        }
    },
    mounted(){
        
    },
    methods: {
        screenData(value){
            this.$nextTick(()=>{
                this.$refs.goodsListCard.setValue(value);
            })
        },
        handleCreateStore(){
            this.$router.push("/sGoodsReleased");
        }
    }
}
</script>

<style lang="scss">
.storeAccountManager{
    .tool-card{
        padding: 10px 10px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid $card-border-color;
    }
    .table-card{
        margin-top: 20px;
        padding: 10px 10px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid $card-border-color;        
    }

}
</style>